<script>
import success from "../../assets/images/svg/success.svg"
import SvgIcon from "../../components/dashboard/payment/svg-icon.vue";
import PageText from "../../components/dashboard/payment/page-text.vue";
export default {
  name: "success",
  components: {PageText, SvgIcon},
  data() {
    return {
      svgIcon: success,
    };
  },
}
</script>

<template>
  <div class="payment-page">
    <div class="container">
      <div class="card shadow-sm p-4">
        <div class="text-center">
          <svg-icon :svg-icon="svgIcon" />
          <page-text
              main-title="Paiement effectué !"
              main-text="Merci pour votre paiement sécurisé en ligne."
              last-text="Passez une excellente journée !"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .payment-page{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>